<template>
  <div ref="goodsManage"
       class="goods-manager">

    <div class="search-header">
      <a-form layout="inline"
              :model="searchForm">
        <a-form-item>
          <a-input-search v-model:value="searchForm.title"
                          placeholder="请输入商品名称"
                          style="width: 320px"
                          @keydown.enter="onSearchSubmit"
                          v-debounce="onSearchSubmit" />
        </a-form-item>
      </a-form>
      <router-link :to="{name: 'goodsManage_create' }">
        <a-button class="border-radius"
                  type="primary"
                  centered
                  style="width: 146px; height: 36px">
          <PlusOutlined />新建商品
        </a-button>
      </router-link>
    </div>

    <a-table :columns="goodsColumns"
             :data-source="goodsList"
             :scroll="{ x: '100%', y: 'calc(100vh - 322px)' }"
             row-key="id"
             :pagination="pagination"
             @change="onPaginationChange"
             :loading="tableLoading">

      <template #example="{ record }">
        <div class="goods-example">
          <img class="mb8 cursor-pointer"
               :src="record.cover"
               style="width: 160px; height: 90px"
               alt="商品封面图" />
          <p class="goods-example__title">{{record.title}}</p>
        </div>

      </template>

      <template #type="{ text }">
        {{ GoodsTypeEnum[text] }}
      </template>

      <template #price="{ text }">
        {{lodash.divide(text, 100)}}
      </template>

      <template #status="{ text }">
        <a-tag v-if="text === 'up'"
               class="goods-status--success">已上架</a-tag>
        <a-tag v-else-if="text === 'draft'"
               class="goods-status--error">未上架</a-tag>
        <a-tag v-else
               class="goods-status--error">已下架</a-tag>
      </template>

      <template #createdTime="{ record }">
        {{$f.datetime(record.createdTime, 'YYYY-MM-DD HH:mm')}}
      </template>

      <template #action="{ record }">

        <router-link :to="{name: 'goodsManage_statistic', query: {id: record.id }}">
          数据
        </router-link>

        <span v-if="record.status === 'up'"
              class="ml16 color-primary cursor-pointer"
              @click='itemDownShelf(record.id)'>下架</span>
        <span v-else
              class="ml16 color-primary cursor-pointer"
              @click='itemUpShelf(record.id)'>上架</span>

        <a-dropdown>
          <svg-icon class="font-16 ml16 color-primary"
                    type="iconmore"
                    @click.stop></svg-icon>

          <template #overlay>
            <a-menu>
              <a-menu-item v-if="record.status === 'up'"
                           @click='openShareDialog(record)'>
                分享
              </a-menu-item>
              <a-menu-item>
                <router-link :to="{name: 'goodsManage_create', query: {isEdit: true, id: record.id }}">
                  修改
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="record.status === 'draft'"
                           @click='openDeleteDialog(record)'>
                删除
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </template>

    </a-table>

    <share-modal v-model:visible="shareDialogVisible"
                 title="分享商品"
                 :item="shareGoods" />

    <delete-modal v-model:visible="deleteModalVisible"
                  title="删除商品"
                  :deleteItem="deleteItem"
                  @delete="deleteGoods"></delete-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive } from "vue";
import { message } from "ant-design-vue";

import SvgIcon from "@/components/SvgIcon";
import DeleteModal from "@/components/DeleteModal";
import ShareModal from "@/components/ShareLinkModal";

import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import router from "@/router";
import goodsApi from "@/service/api/goods";

export default defineComponent({
  name: "GoodsManageIndex",

  components: {
    SvgIcon,
    DeleteModal,
    ShareModal,
  },

  setup() {
    const GoodsTypeEnum = {
      normal: "普通商品",
      classroom: "网校班级",
      course: "网校课程",
    };

    const goodsColumns = [
      {
        title: "商品",
        dataIndex: "goodsId",
        slots: { customRender: "example" },
      },
      {
        title: "商品类型",
        dataIndex: "type",
        slots: { customRender: "type" },
      },
      {
        title: "价格（元）",
        dataIndex: "price",
        slots: { customRender: "price" },
      },
      {
        title: "购买人数",
        dataIndex: "buyerNum",
      },
      {
        title: "状态",
        dataIndex: "status",
        slots: { customRender: "status" },
        width: "150px",
      },
      {
        title: "创建时间",
        dataIndex: "createdTime",
        slots: { customRender: "createdTime" },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
      },
    ];

    const searchForm = reactive({
      title: "",
    });

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, goodsApi.search);

    async function itemDownShelf(id) {
      await goodsApi.itemDownShelf({ id });
      message.success("下架成功");
      fetchPaginationData();
    }

    async function itemUpShelf(id) {
      await goodsApi.itemUpShelf({ id });
      message.success("上架成功");
      fetchPaginationData();
    }

    const deleteModalVisible = ref(false);
    const deleteItem = reactive({});
    async function deleteGoods() {
      await goodsApi.delete({
        id: deleteItem.id,
      });
      message.success("删除成功");
      fetchPaginationData();
      deleteModalVisible.value = false;
    }
    function openDeleteDialog(record) {
      _.assign(deleteItem, record);
      deleteModalVisible.value = true;
    }

    return {
      lodash: _,

      GoodsTypeEnum,
      goodsColumns,
      searchForm,
      goodsList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      itemUpShelf,
      itemDownShelf,

      deleteModalVisible,
      deleteItem,
      deleteGoods,
      openDeleteDialog,

      ...useShareGoods(),
    };
  },
});

function useShareGoods() {
  const shareDialogVisible = ref(false);
  const shareGoods = reactive({
    shareLink: "",
    title: "",
  });
  async function openShareDialog(goods) {
    shareGoods.title = goods.title;
    shareGoods.link = await goodsApi.generateShareUrl({ id: goods.id });
    shareDialogVisible.value = true;
  }

  return {
    shareDialogVisible,
    shareGoods,
    openShareDialog,
  };
}
</script>
<style lang='less' scoped>
.goods-example {
  width: 160px;

  &__title {
    .text-overflow(2);
  }
}

.goods-status {
  &--success {
    padding: 2px @spacing-2x;

    border-color: @color-success;
    background-color: fade(@color-success, 10);
    color: @color-success;
  }

  &--error {
    padding: 2px @spacing-2x;
    border-color: @color-danger;
    background-color: fade(@color-danger, 10);
    color: @color-danger;
  }
}
</style>